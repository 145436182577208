import React, { useState } from "react";
import "./servicemain.css";
import "./Popup";
import pic1 from "./pic1.jpg";
import pic2 from "./pic2.jpg";
import pic3 from "./pic3.jpg";
import pic4 from "./pic4.jpg";
import pic5 from "./pic5.jpg";
import mobilee from '../assets/mobilee';
import content1 from "./consult1.png";
import content2 from "./content2.png";
import content3 from "./content3.png";
import content4 from "./content4.png";
import contentt5 from "../assets/contentt5.png";
import content6 from "./content6.png";
import content7 from "./content7.png";
import content8 from "./content8.png";
import onlinedigital from "../assets/onlinedigital.jpg";

const Servicemain = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  // Service data
  const services = [
    { id: 1, title: "Software Development", image: pic1, description: "Description for Software Development" },
    { id: 2, title: "Android Development", image: pic2, description: "Description for Android Development" },
    { id: 3, title: "Web Designing", image: pic3, description: "Description for Web Designing" },
    { id: 4, title: "Web Development", image: pic4, description: "Description for Web Development" },
    { id: 5, title: "Digital Marketing", image: pic5, description: "Description for Digital Marketing" },
  ];

  // Open popup handler
  const openPopup = (service) => {
    setSelectedService(service);
    setIsOpen(true);
  };

  // Close popup handler
  const closePopup = () => {
    setIsOpen(false);
    setSelectedService(null); // Clear the selected service
  };
  // State to manage the visibility of content sections
  const [visibleSections, setVisibleSections] = useState({
    itConsulting: false,
    onlineTraining: false,
    softwareDevelopment: false,
    mobileAppDevelopment: false,
    commercialWebDevelopment: false,
    digitalMarketing: false,
    businessSolutions: false,
    mediaAdvertisement: false,
  });

  const toggleSection = (section) => {
    setVisibleSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div>
      <div className="heading-service">
        <h1>
          S<span className="highlight">ER</span>VICES
        </h1>
        <h4>WHERE QUALITY IS MEASURED BY YOUR SATISFACTION AND SUCCESS</h4>
      </div>
      <div id="content">
        <p id="para">
        At Interface11 Software Solutions Pvt. Ltd. IT means achieving groundbreaking results, ultimate
          satisfaction at total peace of mind which allows you to not only run
          and maintain your business but take it to the next level. Our plethora
          of solutions and services combined with our healing and indigenous
          approach ensures that your requirements are met on time, within
          budget, with unsurpassed quality, greater efficiency and
          responsiveness to your business, and the ability to transform
          investments into strategic initiatives rather than tactical functions.
          By prioritizing your satisfaction and success, we foster long-term relationships built on trust and collaboration.Our commitment to quality ensures that every solution we deliver is customized to meet your specific requirements, enhancing overall effectiveness.Your success is our success. We strive to be more than just a service provider; we aim to be your trusted partner in achieving your business goals.
        </p>
      </div>
      <div id="images">
    
      <div id="id1">
          <img src={pic1} alt="Software Development" className="cimages" />
          <h2 className="blue">SOFTWARE DEVELOPMENT</h2>
        </div>
         <div id="id2">
          <img src={pic2} alt="Android Development" className="cimages" />
          <h2 className="blue">ANDROID DEVELOPMENT</h2>
        </div>
        <div id="id3">
          <img src={pic3} alt="Web Designing" className="cimages" />
          <h2 className="red">WEB DESIGNING</h2>
        </div>
        <div id="id4">
          <img src={pic4} alt="Web Development" className="cimages" />
          <h2 className="teal">WEB DEVELOPMENT</h2>
        </div>
        <div id="id5">
          <img src={pic5} alt="Digital Marketing" className="cimages" />
          <h2 className="lightblue">DIGITAL MARKETING</h2>
        </div>
      </div>

      <div className="content-container-blue">
        <div className="content-text-blue">
          <h2>IT Consulting</h2>
          <hr />
          <p>
          Interface11 Software Solutions Pvt. Ltd. IT Consulting is directed towards IT
            initiatives that companies wish to take up in the quest to sustain
            and grow the business. With specialist knowledge and experience
            across many different market sectors, the Interface11 software solutions consultants
            can confidently deliver the right solution for you – now and for the
            future.

          </p>
          {visibleSections.itConsulting && (
            <p>
              We work with clients to develop comprehensive IT strategies that align with business goals. Our strategic planning includes assessing current IT capabilities, identifying gaps, and formulating a roadmap to achieve desired outcomes.
            </p>
          )}
          <button className="btn1" onClick={() => toggleSection('itConsulting')}>
            {visibleSections.itConsulting ? "Read Less" : "Read More"}
          </button>
        </div>
        <div className="img-blue">
          <img src={content1} alt="IT Consulting" />
        </div>
      </div>

      <div className="content-container-white">
        <div className="img-white imgWhite">
          <img src={content2} alt="Online & Corporate Training" />
        </div>
        <div className="content-text-white">
          <h2>Online & Corporate Training</h2>
          <hr />
          <p>
          Interface11 software solutions Pvt. Ltd. is Leader in Online and Corporate IT Training
            solutions for various new generation Software Trainings like Angular, AWS, C & C++, Devops, Java Full Stack, PHP, React JS, Power BI and much more precisely
            addressing business needs of our customers. We feel pleasure in
            introducing ourselves as Top rated trainers in the field of
            Information Technology and placement Consultants. Founded by IT
            experience people, our mission is to deliver the highest-quality,
            value-added services in the Field of Training, Job support and
            consulting for both their short-term and long-term needs.

          </p>
          {visibleSections.onlineTraining && (
            <p>
             We understand that different organizations have unique training requirements. Our training programs are fully customizable, allowing businesses to select topics and formats that best suit their teams.
            </p>
          )}
          <button className="btn2" onClick={() => toggleSection('onlineTraining')}>
            {visibleSections.onlineTraining ? "Read Less" : "Read More"}
          </button>
        </div>
      </div>

      
      <div className="content-container-blue">
        <div className="content-text-blue">
          <h2>Software Development</h2>
          <hr />
          <p>
          Interface11 Software Solutions Pvt. Ltd. development services are meant for designing a software
            that has been designed, developed and tested for a specific business
            requirement. Interface11 has more well-organized software
            development processes that are cost effective yet provide high
            quality deliverables. It has acquired complete knowledge in the art
            of planning and designing custom software solutions, which is the
            key for successful IT development services.

          </p>
          {visibleSections.softwareDevelopment && (
            <p>
             We develop tailored software solutions designed specifically for your business requirements. Our team collaborates closely with clients to understand their needs, resulting in software that fits seamlessly into their operations.
            </p>
          )}
          <button className="btn1" onClick={() => toggleSection('softwareDevelopment')}>
            {visibleSections.softwareDevelopment ? "Read Less" : "Read More"}
          </button>
        </div>
        <div className="img-blue imgblue">
          <img src={content3} alt="Software Development" />
        </div>                  
      </div>
    
      <div className="content-container-white">
      <div className="img-white mobile">
          <img src={mobilee} />
        </div>   
        <div className="content-text-white">
          <h2>Mobile App Development</h2>
          <hr />

          <p>
          The mobile app development world is changing every while. Mobile
            devices across the globe are being used for varied purposes. There
            is need to utilise latest technologies and stay adrift of this
            rapidly changing scenario. If website was a way to increase your ROI
            in the last decade, then custom mobile application is the name of
            the game in the present while.


          </p>
          {visibleSections.softwareDevelopment && (
            <p>
              With the increasing reliance on mobile devices, we offer mobile app development services for iOS and Android platforms. Our mobile solutions are designed for optimal performance and user satisfaction.
            </p>
          )}
          <button className="btn2" onClick={() => toggleSection('softwareDevelopment')}>
            {visibleSections.softwareDevelopment ? "Read Less" : "Read More"}
          </button>
        </div>       
      </div>


      <div className="content-container-blue">
        <div className="content-text-blue">
          <h2>Commercial Web Development</h2>
          <hr />
          <p>
          We excel in building commercial websites right from general to
            E-commerce websites including Portfolio and Designing websites at
            the best possible cost. “So that getting your business an online
            platform doesn’t bother your pocket.” Customers need to be engaged
            on a continuous and on-going basis in order to provide maximum
            satisfaction. A strong Digital Experience is the key! Companies that
            adapt to customer needs and expectations are successful in engaging
            and satisfying their customers.
          </p>
          {visibleSections.softwareDevelopment && (
            <p>
             We create custom websites tailored to your specific business requirements. Our development process is highly collaborative, ensuring that your vision is translated into a website that reflects your brand identity and values.
            </p>
          )}
          <button className="btn1" onClick={() => toggleSection('softwareDevelopment')}>
            {visibleSections.softwareDevelopment ? "Read Less" : "Read More"}
          </button>
        </div>
        <div className="img-blue">
          <img src={contentt5} />
        </div>               
      </div>

      <div className="content-container-white">
      <div className="img-white">
          <img src={onlinedigital} />
        </div>
          <div className="content-text-white">
          <h2>Online/Digital Marketing</h2>
          <hr />
          <p>
            We provide the services that are listed below. “So that our clients
            maintain a constant hold over their positions on Google.”
          </p>
          <div className="lists">
          <ol>
            <li>SEO</li>
            <li>SEM</li>
            <li>SMO</li>
          </ol>
          </div>
          {visibleSections.softwareDevelopment && (
            <p>
              We implement targeted SEM campaigns, including Pay-Per-Click (PPC) advertising, to increase your visibility on search engines. Our approach focuses on maximizing your ROI by carefully selecting keywords, creating compelling ad copy, and continually optimizing campaigns for better performance.
            </p>
          )}
          <button className="btn2" onClick={() => toggleSection('softwareDevelopment')}>
            {visibleSections.softwareDevelopment ? "Read Less" : "Read More"}
          </button>
        </div>       
      </div>

      <div className="content-container-blue">
        <div className="content-text-blue">
          <h2>Business Solutions</h2>
          <hr />
          <p>
          We ease our partners' complicated business procedures by creating
            systems/software that suits their unique requirements. We provide
            personalized solutions to all our clients and make sure to provide
            the best at the lowest cost. “Because we believe every unique
            business needs unique solutions.” Our commitment is to deliver the
            best services at the most competitive prices, without compromising
            on quality. Whether you're a startup or an established enterprise,
            we believe in creating value through innovation and collaboration.
            "Because we believe every unique business needs unique solutions."

          </p>
          {visibleSections.softwareDevelopment && (
            <p>
               In today’s competitive landscape, businesses must streamline their processes and leverage technology to stay ahead. At Interface11 Solutions, we specialize in creating customized business solutions that address unique challenges and optimize operations. Our goal is to empower your organization through innovative systems and strategies that drive efficiency and growth.
            </p>
          )}
          <button className="btn1" onClick={() => toggleSection('softwareDevelopment')}>
            {visibleSections.softwareDevelopment ? "Read Less" : "Read More"}
          </button>
        </div>
        <div className="img-blue blueimg">
          <img src={content7} />
        </div>
      </div>

      <div className="content-container-white">
      <div className="img-white imgWhite-1">
          <img src={content8} />
        </div>

        <div className="content-text-white">
          <h2>Media Advertisement</h2>
          <hr />

          <p>
          We help you create a better perception among your clients/customers
            by creating thought-provoking media ads that help you boost your
            brand image. We create and post your media ads over appropriate
            social media sites as well as cinema halls. We also help you promote
            your brand through TV and radio advertisements.


          </p>
          {visibleSections.softwareDevelopment && (
            <p>
            In today’s digital age, effective media advertising is crucial for businesses seeking to establish a strong presence and connect with their target audience. At Interface11 Solutions, we specialize in creating impactful media advertisements that resonate with consumers and enhance brand visibility.
            </p>
          )}
          <button className="btn2" onClick={() => toggleSection('softwareDevelopment')}>
            {visibleSections.softwareDevelopment ? "Read Less" : "Read More"}
          </button>
        </div>       
      </div>
    
    </div>
  );
};

export default Servicemain;
