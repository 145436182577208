// Popup.js
import React from 'react';
import Modal from 'react-modal';
import './Popup.css'; // Make sure to create styles for the modal

Modal.setAppElement('#root'); // Accessibility: set the app element

const Popup = ({ isOpen, onRequestClose, content }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="modal" overlayClassName="overlay">
      <h2>{content.title}</h2>
      <p>{content.description}</p>
      <button onClick={onRequestClose}>Close</button>
    </Modal>
  );
};

export default Popup;
